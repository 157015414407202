import { Alert, Col, Form, message, Radio, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import TitleWithBack from "../../../containers/TitleWithBack";
import { signalConfigurationTitle } from "../title";
import FormInput from "../../input/FormInput";
import {
  prepareConfigJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../../api/constants";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import ButtonConfItem from "../../ButtonConfItem";
import ButtonItem from "../../ButtonItem";
import { getInstallation } from "../../../api/services/installationsService";

type SignalConfigurationTableProps = {
  installation_id: string;
};

const SignalConfigurationTable: React.FC<SignalConfigurationTableProps> = ({
  installation_id,
}) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [initialJob, setInitialJob] = useState({
    spd_en: "enabled", //velocita volano
    drag_en: "enabled", //velocita trascinamento,
    ta1_wthresh: 0,
    ta2_wthresh: 0,
    ta3_wthresh: 0,
    size: 0,
  });
  useEffect(() => {
    getZfs(installation_id).then((res: any) => {
      if (res && res.files) {
        const netFile = res.files.find((file: any) => file.path === "net.json");
        const paramsFile = res.files.find(
          (file: any) => file.path === "params.json"
        );
        if (netFile) {
          getZfsFile(installation_id, netFile.id)
            .then((resFile) => {
              setNet(resFile);
            })
            .catch((error) => {
              console.error("Error while fetching ZFS file:", error);
              setError(error.message);
            });
        }
        if (paramsFile) {
          getZfsFile(installation_id, paramsFile.id).then((resFile: any) => {
            setInitialJob({
              spd_en: resFile?.SPD_EN || "enabled",
              drag_en: resFile?.DRAG_EN || "enabled",
              ta1_wthresh: resFile?.TA1?.w_thresh
                ? resFile?.TA1?.w_thresh / 1000
                : 0,
              ta2_wthresh: resFile?.TA2?.w_thresh
                ? resFile?.TA2?.w_thresh / 1000
                : 0,
              ta3_wthresh: resFile?.TA3?.w_thresh
                ? resFile?.TA3?.w_thresh / 1000
                : 0,
              size: resFile?.SIZE ? resFile?.SIZE / 1000 : 0,
            });
            setLoading(false);
          });
        }
      } else {
        console.log(
          "Errore durante il recupero dei parametri. Riporva più tardi."
        );
        setLoading(false);
      }
    });
  }, []);

  const [loadFirmware, setLoadFirmware] = useState<boolean>(true);
  const [firmwareType, setFirmwareType] = useState<
    "standard" | "pinza_amperometrica"
  >("pinza_amperometrica");
  useEffect(() => {
    getInstallation({ id: installation_id }).then((res) => {
      if (res && res.installation) {
        setFirmwareType(res.installation.firmware_type);
      }
      setLoadFirmware(false);
    });
  }, []);

  const [net, setNet] = useState<any>({
    IFCS: [
      {
        ifc_name: "wifi",
        ifc_params: {
          ssid: "assistenza",
          pwd: "12345678",
        },
      },
      {
        ifc_name: "cellular",
        ifc_params: { apn: "iot.secure" },
      },
    ],
  });

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const netFile = {
        file: new File(
          [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
          "net.json"
        ),
      };
      const paramsFile = {
        file: new File(
          [
            new TextEncoder().encode(
              JSON.stringify(
                prepareParamsJson(
                  values.ta1_wthresh * 1000, //da kW a W
                  values.ta2_wthresh * 1000, //da kW a W
                  values.ta3_wthresh * 1000, //da kW a W
                  values.spd_en as "enabled" | "disabled",
                  values.drag_en as "enabled" | "disabled",
                  firmwareType === "pinza_amperometrica" ? 1 : 0,
                  values.size * 1000 //da kW a W
                )
              )
            ),
          ] as BlobPart[],
          "params.json"
        ),
      };
      const sensorsFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
          ] as BlobPart[],
          "sensors.json"
        ),
      };
      const configFile = {
        file: new File(
          [
            new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
          ] as BlobPart[],
          "config.json"
        ),
      };
      init(installation_id).then(
        async () =>
          await uploadFiles(installation_id, [
            netFile,
            paramsFile,
            sensorsFile,
            configFile,
          ]).then(async (res: any) => {
            if (res && res.files) {
              await commit(installation_id, true).then((r: any) => {
                if (r && r.version) {
                  message.success("Parametri aggiornati con successo");
                  history.goBack();
                } else {
                  message.error(
                    "Errore durante l'aggiornamento dei parametri. Riprova più tardi."
                  );
                }
              });
            }
          })
      );
    });
  };

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(installation_id)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    checkStatus();
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading || loadFirmware) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={signalConfigurationTitle}
        key="signal_configuration_title"
      />
      {runningZfs && (
        <div style={{ width: "100%" }}>
          <Alert
            message="Aggiornamento dei parametri in corso"
            type="warning"
          />
        </div>
      )}
      <div
        className="my-signalconfiguration-container my-signalconfiguration-container-responsive"
        key="signal_configuration_div"
      >
        <Form layout="vertical" key={1} form={form} name="job_panel">
          <Row gutter={24} align="middle">
            <Col span={12}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Velocità volano
                <Form.Item key="spd_en_formitem" name="spd_en">
                  <Radio.Group
                    key="spd_en"
                    name="spd_en"
                    defaultValue={initialJob.spd_en}
                  >
                    <Radio value="enabled">Abilitato</Radio>
                    <Radio value="disabled">Disabilitato</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                Velocità trascinamento
                <Form.Item key="drag_en_formitem" name="drag_en">
                  <Radio.Group
                    key="drag_en"
                    name="drag_en"
                    defaultValue={initialJob.drag_en}
                  >
                    <Radio value="enabled">Abilitato</Radio>
                    <Radio value="disabled">Disabilitato</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <FormInput
                key="ta1_wthresh_forminput"
                keyValue="ta1_wthresh"
                name="ta1_wthresh"
                rules={[{ required: true, message: "Campo obbligatorio" }]}
                placeholder="Soglia di lavoro consumo generale (kW)"
                type="number"
                value={initialJob.ta1_wthresh}
              />
            </Col>
            <Col span={12}>
              <FormInput
                key="ta2_wthresh_forminput"
                keyValue="ta2_wthresh"
                name="ta2_wthresh"
                rules={[{ required: true, message: "Campo obbligatorio" }]}
                placeholder="Soglia di lavoro volano (kW)"
                type="number"
                value={initialJob.ta2_wthresh}
              />
            </Col>
            <Col span={12}>
              <FormInput
                key="ta3_wthresh_forminput"
                keyValue="ta3_wthresh"
                name="ta3_wthresh"
                rules={[{ required: true, message: "Campo obbligatorio" }]}
                placeholder="Soglia di lavoro motore di trascinamento (kW)"
                type="number"
                value={initialJob.ta3_wthresh}
              />
            </Col>
            <Col span={12}>
              <FormInput
                key="size_forminput"
                keyValue="size"
                name="size"
                rules={[{ required: true, message: "Campo obbligatorio" }]}
                placeholder="Taglia macchina (kW)"
                type="number"
                value={initialJob.size}
              />
            </Col>
          </Row>
          <div className="btn-container" key={"add_installation_btn"}>
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={handleSubmit}
              disabled={error || runningZfs ? true : false}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignalConfigurationTable;
