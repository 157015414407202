import { connect } from "react-redux";
import { Store } from "../../../reducers";
import { Dispatch } from "react";
import { Action } from "../../../actions";
import DeviceUpdateConnectivity from "../../../components/pages/deviceConnectionConfiguration/DeviceUpdateConnectivity";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

const mapStateToProps = (state: Store) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceUpdateConnectivity);
