export const dataRealTimeTitle = "Dati Real Time";
export const dataHistoryTitle = "Storico Dati";
export const alarmsTitle = "Allarmi";
export const adminPanelTitle = "Admin Panel";
export const addInstallerTitle = "Crea Installatore";
export const updateInstallerTitle = "Modifica Installatore";
export const associationsInstallersTitle = "Associazioni Installatori";
export const addSubscriptionTitle = "Crea Abbonamento";
export const updateSubscriptionTitle = "Modifica Abbonamento";
export const addWireTitle = "Registra Nuovo Cavo";
export const addUserTitle = "Crea Utente";
export const updateUserTitle = "Modifica Utente";
export const addInstallationTitle = "Crea Installazione";
export const updateInstallationTitle = "Modifica Installazione";
export const selectInstallationTitle = "Scegli Installazione da Modificare";
export const installerListTitle = "Lista Installatori";
export const subscriptionsListTitle = "Lista Abbonamenti";
export const installationsListTitle = "Lista Installazioni";
export const connectionTitle = "Configurazione Box";
export const wiresListTitle = "Lista Cavi";
export const signalConfigurationTitle = "Calibrazione Segnali";
export const updateWireTitle = "Modifica Cavo";
export const ConfiguratorTitle = "Configurator";
export const ReportTitle = "Report";
