import React from "react";
import "../../navbar/Navbar.css";
import "./alarmTable.css";
import "../../../App.css";
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from "../../responsiveTable";
import { alarmsTitle } from "../title";
import { GetAllAlarms } from "../../../api/services/installationsService";
import { alarmColumns } from "./columns";

type AlarmTableProps = {};

const AlarmTable: React.FC<AlarmTableProps> = () => {
  document.body.style.background = "#f5f5f5c7";

  return (
    <>
      <CustomTitle title={alarmsTitle} />
      <ResponsiveTable
        columns={alarmColumns}
        getPageAndSortedData={GetAllAlarms}
        defaultSort="-start"
        responseDataName="alarms"
      />
    </>
  );
};

export default AlarmTable;
