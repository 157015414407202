import React from "react";
import { Button, Card, CardContent, Grid } from "@material-ui/core";
import { adminPanelIconCards } from "./constants";
import { useHistory } from "react-router-dom";
import "./homepage.css";

const AdminPanel: React.FC = () => {
  const history = useHistory();

  return (
    <Grid container justify={"center"} spacing={2} style={{ marginTop: "5%" }}>
      {adminPanelIconCards.map((iconItem, index) => (
        <Grid
          className="home-icon-card"
          item
          xs={12}
          sm={4}
          key={`grid_${index}`}
        >
          <Card key={`card_${index}`}>
            <CardContent className="card-content">
              <img alt="logo" className="card-logo" src={iconItem.img} />
            </CardContent>
            <div className="card-buttons">
              {iconItem.text.map((buttonText, buttonIndex) => (
                <Button
                  style={{ textTransform: "none" }}
                  key={buttonIndex}
                  className="z-button"
                  onClick={() => history.push(iconItem.link[buttonIndex])}
                >
                  {buttonText}
                </Button>
              ))}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default AdminPanel;
