import { connect } from "react-redux";
import { Store } from "../../../reducers";
import { Dispatch } from "react";
import { Action } from "../../../actions";
import UploadFw from "../../../components/pages/deviceConnectionConfiguration/UploadFw";

const mapDispatchToProps = (dispatch: Dispatch<Action>) => {
  return {};
};

const mapStateToProps = (state: Store) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadFw);
