import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import "../../../App.css";
import { dateType, linkType, stringType } from "../../../utilities/utilities";

export const tableColumn: (
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (linkFunction) => [
  {
    label: "Utenza",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={linkType}
          value={data.tableData[data.rowIndex].company_info.name}
          link={() => {
            linkFunction(data.tableData[data.rowIndex].id);
          }}
        />
      ),
    },
  },
  {
    label: "SDI",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.sdi}
        />
      ),
    },
  },
  {
    label: "PEC",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.pec}
        />
      ),
    },
  },
  {
    label: "VAT",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.vat_number}
        />
      ),
    },
  },
  {
    label: "Telefono",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.phone}
        />
      ),
    },
  },
  {
    label: "Indirizzo",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={`${data.tableData[data.rowIndex].company_info.address}, ${
            data.tableData[data.rowIndex].company_info.city
          }, ${data.tableData[data.rowIndex].company_info.province}`}
        />
      ),
    },
  },
  {
    label: "CAP",
    type: "string",
    name: "company_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={stringType}
          value={data.tableData[data.rowIndex].company_info.zip_code}
        />
      ),
    },
  },
  {
    label: "Scadenza",
    name: "expiry_date",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data) => {
        return <ResponsiveTableCell type={dateType} value={value} />;
      },
    },
  },
];
