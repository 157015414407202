import {
  changeStatus,
  getAllSubscriptions,
} from "../../../api/services/subscriptionService";
import ResponsiveTable from "../../responsiveTable";
import { tableColumn } from "./tableColumn";
import { ChangeStatusRequest } from "../../../api/requests/subscription";

type SubscriptionManagementProps = {};

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = () => {
  document.body.style.background = "#f5f5f5c7";

  const manageSubscription = (
    subscription_id: string,
    requested_status: string
  ) => {
    const request: ChangeStatusRequest = {
      subscription_id: subscription_id,
      requested_status: requested_status,
    };
    changeStatus(request).then((res) => {
      if (res && res.subscription) {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <ResponsiveTable
        columns={tableColumn(manageSubscription)}
        getPageAndSortedData={getAllSubscriptions}
        defaultSort="expiry_date"
        responseDataName="subscriptions"
      />
    </>
  );
};

export default SubscriptionManagement;
