import "./summary.css";
import React from "react";
import { Card, Divider } from "antd";
import { Installation } from "../../../../api/requests/installationsService";
import moment from "moment";
import Title from "antd/es/typography/Title";
import CustomTag from "./customTag";
import { Typography } from "@material-ui/core";

type InstallationSummaryProps = {
  selected_installation: Installation | null;
};

const InstallationSummary: React.FC<InstallationSummaryProps> = ({
  selected_installation,
}) => {
  return (
    <Card
      style={{ minHeight: "290px", maxHeight: "290px", overflow: "scroll" }}
      hoverable
      title={
        <Title level={4} className="tableTitle">
          Installazione
        </Title>
      }
      headStyle={{ maxHeight: "4vh" }}
    >
      <div key="container_div_1">
        <div className="tag-container">
          <Typography>Nome:</Typography>
          <Typography>{selected_installation?.name || ""}</Typography>
        </div>
      </div>
      <Divider />

      <div key="container_div_2">
        <div className="tag-container">
          <Typography>Stato Dispositivo:</Typography>
          {selected_installation &&
          selected_installation.status === "online" ? (
            <CustomTag color="green" text="online" />
          ) : (
            <CustomTag color="red" text="offline" />
          )}
        </div>
        <Divider />
      </div>
      <div key="container_div_3">
        <div className="tag-container">
          <Typography>Data Creazione:</Typography>
          <Typography>
            {moment(selected_installation?.created_at || "").format(
              "DD/MM/YYYY"
            )}
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default InstallationSummary;
