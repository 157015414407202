import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LastWiresElement } from "../../../api/requests/installationsService";
import { GetWires } from "../../../api/services/wiresService";
import CustomTitle from "../../CustomTitle";
import ResponsiveTable from "../../responsiveTable";
import WiresModal from "../installation/summaryCards/WiresModal";
import { wiresListColumns as modalColumn } from "../installation/summaryCards/WiresColumns";
import { wiresListTitle } from "../title";
import { wiresListColumnsWithLink } from "./columns";
import RfidModal from "../installation/summaryCards/RfidModal";

type WireListTableProps = {
  role: string;
  link?: (p: string) => string;
};

const WireListTable: React.FC<WireListTableProps> = ({ role, link }) => {
  document.body.style.background = "#f5f5f5c7";

  const [wires, setWires] = useState<any[] | null>(null);
  const [selectedWire, setSelectedWire] = useState<LastWiresElement | null>(
    null
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [rfid, setRfid] = useState<boolean>(false);
  const handleOpenRfid = () => setRfid(true);
  const handleCloseRfid = () => setRfid(false);

  useEffect(() => {
    GetWires("-1", "", "").then((res) => {
      if (res && !res.err) {
        setWires(res.wires);
      } else {
        message.error(res?.err?.message || "Operazione fallita.");
      }
    });
  }, []);

  const history = useHistory();

  if (!link) {
    return (
      <>
        <CustomTitle title={wiresListTitle} />
        <ResponsiveTable
          columns={modalColumn(
            role,
            handleOpen,
            handleOpenRfid,
            setSelectedWire,
            (wires || []) as LastWiresElement[],
            false,
            true
          )}
          getPageAndSortedData={GetWires}
          defaultSort="name"
          responseDataName="wires"
        />
        <WiresModal
          open={openModal}
          handleClose={handleClose}
          wire={selectedWire}
        />
        <RfidModal
          wire={selectedWire}
          open={rfid}
          handleClose={handleCloseRfid}
        />
      </>
    );
  } else {
    const linkFunction: (value: string) => void = (value: string) =>
      history.push(link(value));

    return (
      <>
        <CustomTitle title={wiresListTitle} />
        <ResponsiveTable
          columns={wiresListColumnsWithLink(linkFunction)}
          getPageAndSortedData={GetWires}
          defaultSort="name"
          responseDataName="wires"
        />
      </>
    );
  }
};

export default WireListTable;
