import "./summary.css";
import React, { useCallback, useEffect, useState } from "react";
import WiresModal from "./WiresModal";
import { getLastUsedWires } from "../../../../api/services/timeSeries";
import { LastWiresElement } from "../../../../api/requests/installationsService";
import ResponsiveTable from "../../../responsiveTable";
import { wiresListColumns } from "./WiresColumns";
import RfidModal from "./RfidModal";
import Title from "antd/es/typography/Title";

type WiresSummaryProps = {
  installationId: string;
  role: string;
};

const WiresSummary: React.FC<WiresSummaryProps> = ({
  installationId,
  role,
}) => {
  const [selectedWire, setSelectedWire] = useState<LastWiresElement | null>(
    null
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const [lastWires, setLastWires] = useState<LastWiresElement[]>([]);
  const reqWires = useCallback(() => {
    getLastUsedWires({ device_id: installationId }).then((res) => {
      if (res && res.wires) {
        setLastWires(res.wires);
      }
    });
  }, []);

  useEffect(() => {
    reqWires();
    const intervalId = setInterval(reqWires, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [rfid, setRfid] = useState<boolean>(false);
  const handleOpenRfid = () => setRfid(true);
  const handleCloseRfid = () => setRfid(false);

  return (
    <>
      <Title level={4} className="tableTitle">
        Cavi Utilizzati
      </Title>
      <ResponsiveTable
        columns={wiresListColumns(
          role,
          handleOpen,
          handleOpenRfid,
          setSelectedWire,
          lastWires,
          true,
          true
        )}
        data={lastWires}
      />
      <WiresModal
        open={openModal}
        handleClose={handleClose}
        wire={selectedWire}
      />
      <RfidModal
        wire={selectedWire}
        open={rfid}
        handleClose={handleCloseRfid}
      />
    </>
  );
};

export default WiresSummary;
