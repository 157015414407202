import { CloudDownloadTwoTone } from "@material-ui/icons";
import { iconType, stringType } from "../../../utilities/utilities";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import { BackendUrl } from "../../../api/constants";

export const downloadFile = (url: string) => {
  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = url;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
};

export const reportListColumns: ResponsiveTableColumnType[] = [
  {
    label: "Nome",
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => {
        return <ResponsiveTableCell type={stringType} value={value} />;
      },
    },
  },
  {
    label: "Descrizione",
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => {
        return <ResponsiveTableCell type={stringType} value={value} />;
      },
    },
  },
  {
    label: "Inizio",
    name: "start",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: "Fine",
    name: "end",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: "Download",
    name: "id",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={iconType}
          link={async () => {
            downloadFile(
              `${BackendUrl}/reportsmanager/wires/${
                data.tableData[data.rowIndex].wire_id
              }/${data.tableData[data.rowIndex].id}/download`
            );
          }}
          value={""}
          icon={<CloudDownloadTwoTone />}
        />
      ),
    },
  },
];
