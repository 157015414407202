import "./summary.css";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { rfidModalComponents } from "./costant";
import ResponsiveTable from "../../../responsiveTable";
import { GetRfidWires } from "../../../../api/services/wiresService";
import { LastWiresElement } from "../../../../api/requests/installationsService";

type RfidModalProps = {
  wire: LastWiresElement | null;
  open: boolean;
  handleClose: () => void;
};

const RfidModal: React.FC<RfidModalProps> = ({ wire, open, handleClose }) => {
  const [wires, setWires] = useState<any[]>([]);
  useEffect(() => {
    GetRfidWires(wire?.id || "", wire?.rfid || "").then((res) => {
      if (res && res.wire_history) {
        setWires(res.wire_history);
      } else {
        setWires([]);
      }
    });
  }, [wire]);

  if (!wire) {
    return null;
  }

  return (
    <>
      <Dialog
        open={open && wires.length > 0}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> Storico cavi </DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableBody>
                <ResponsiveTable columns={rfidModalComponents} data={wires} />
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RfidModal;
