import React, { useEffect, useState } from "react";
import "./signalConfiguration.css";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Divider, Form, Radio } from "antd";
import TitleWithBack from "../../../containers/TitleWithBack";
import { signalConfigurationTitle } from "../title";
import { Job1, Job2, Job3, Job4 } from "./costant";
import FormInput from "../../input/FormInput";
import { JobRequest } from "../../../api/requests/installationsService";
import OperationResult from "../../OperationResult";
import { radioType } from "../../../utilities/utilities";
import { getJob, setJob } from "../../../api/services/installationsService";

type SignalConfigurationProps = {
  installation_id: string;
};

const SignalConfiguration: React.FC<SignalConfigurationProps> = ({
  installation_id,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [initialJob, setInitialJob] = useState({
    config: {
      ta_threshold: "", //consumo potenza
      ta_multi: "", //consumo potenza
      spd_en: "", //velocita volano
      spd_min: "", //velocita volano
      spd_max: "", //velocita volano
      drag_en: "", //velocita trascinamento
      am_en: "", //automatico manuale
    },
    installation_id: "",
    last_set_status: "Never done",
  });
  const [disabled, setDisabled] = useState<boolean[]>([
    true,
    false,
    false,
    false,
  ]);
  useEffect(() => {
    getJob(installation_id, "calibration").then((res) => {
      if (res && res.calibration) {
        setInitialJob(res.calibration);
      }
      setLoading(false);
    });
  }, [installation_id]);

  const [form] = Form.useForm();
  const submitJob1 = () => {
    return form.validateFields().then((values) => {
      const request: JobRequest = {
        config: {
          ta_threshold: Number(values.ta_threshold),
          ta_multi: Number(values.ta_multi),
          spd_en: values.spd_en,
          spd_min: Number(values.spd_min),
          spd_max: Number(values.spd_max),
          drag_en: values.drag_en,
          am_en: values.am_en,
        },
      };
      setJob(installation_id, "calibration", request).then((res) => {
        if (res && !res.err) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      });
    });
  };

  if (status) {
    return (
      <OperationResult status={status} operation={"create"} entity={"signal"} />
    );
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <TitleWithBack
        title={signalConfigurationTitle}
        key="signal_configuration_title"
      />
      <div
        className="my-signalconfiguration-container my-signalconfiguration-container-responsive"
        key="signal_configuration_div"
      >
        <Form layout="vertical" key={1} form={form} name="job_panel">
          <Divider>Consumo Di Potenza</Divider>
          {Job1(initialJob).map((el) => {
            if (el.type === radioType) {
              return (
                <div className="form__group field" key={el.key + "div"}>
                  <label
                    key={el.key + "label"}
                    htmlFor="name"
                    className="form__label"
                  >
                    {el.label}
                  </label>
                  <Form.Item
                    rules={el.rules || undefined}
                    name={el.name}
                    initialValue={el.value}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setDisabled([
                          disabled[0],
                          e.target.value === "disabled" ? true : false,
                          disabled[2],
                        ])
                      }
                    >
                      {(el?.option || []).map((opt) => {
                        return <Radio value={opt.value}> {opt.label} </Radio>;
                      })}
                    </Radio.Group>
                  </Form.Item>
                </div>
              );
            } else {
              return (
                <FormInput
                  key={el.key + "_form_input"}
                  name={el.name}
                  placeholder={el.label}
                  type={el.type}
                  value={el.value}
                  keyValue={el.key}
                  rules={!disabled[0] ? undefined : el.rules}
                  disabled={!disabled[0]}
                />
              );
            }
          })}
          <Divider>Velocità Volano</Divider>
          {Job2(initialJob).map((el) => {
            if (el.type === radioType) {
              return (
                <div className="form__group field" key={el.key + "div"}>
                  <label
                    key={el.key + "label"}
                    htmlFor="name"
                    className="form__label"
                  >
                    {el.label}
                  </label>
                  <Form.Item
                    rules={el.rules || undefined}
                    name={el.name}
                    initialValue={el.value}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setDisabled([
                          disabled[0],
                          e.target.value === "disabled" ? true : false,
                          disabled[2],
                        ])
                      }
                    >
                      {(el?.option || []).map((opt) => {
                        return <Radio value={opt.value}> {opt.label} </Radio>;
                      })}
                    </Radio.Group>
                  </Form.Item>
                </div>
              );
            } else {
              return (
                <FormInput
                  key={el.key + "_form_input"}
                  name={el.name}
                  placeholder={el.label}
                  type={el.type}
                  value={el.value}
                  keyValue={el.key}
                  rules={disabled[1] ? undefined : el.rules}
                  disabled={disabled[1]}
                />
              );
            }
          })}
          <Divider>Velocità Di Trascinamento</Divider>
          {Job3(initialJob).map((el) => {
            if (el.type === radioType) {
              return (
                <div className="form__group field" key={el.key + "div"}>
                  <label
                    key={el.key + "label"}
                    htmlFor="name"
                    className="form__label"
                  >
                    {el.label}
                  </label>
                  <Form.Item
                    rules={el.rules || undefined}
                    name={el.name}
                    initialValue={el.value}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setDisabled([
                          disabled[0],
                          disabled[1],
                          Boolean(e.target.value),
                        ])
                      }
                    >
                      {(el?.option || []).map((opt) => {
                        return <Radio value={opt.value}> {opt.label} </Radio>;
                      })}
                    </Radio.Group>
                  </Form.Item>
                </div>
              );
            } else {
              return (
                <FormInput
                  key={el.key + "_form_input"}
                  name={el.name}
                  placeholder={el.label}
                  type={el.type}
                  value={el.value}
                  keyValue={el.key}
                  rules={el.rules}
                  disabled={!disabled[2] ? undefined : !disabled[2]}
                />
              );
            }
          })}
          <Divider>Stato Macchina Automatico/Manuale</Divider>
          {Job4(initialJob).map((el) => {
            if (el.type === radioType) {
              return (
                <div className="form__group field" key={el.key + "div"}>
                  <label
                    key={el.key + "label"}
                    htmlFor="name"
                    className="form__label"
                  >
                    {el.label}
                  </label>
                  <Form.Item
                    rules={el.rules || undefined}
                    name={el.name}
                    initialValue={el.value}
                  >
                    <Radio.Group
                      onChange={(e) =>
                        setDisabled([
                          disabled[0],
                          disabled[1],
                          Boolean(e.target.value),
                        ])
                      }
                    >
                      {(el?.option || []).map((opt) => {
                        return <Radio value={opt.value}> {opt.label} </Radio>;
                      })}
                    </Radio.Group>
                  </Form.Item>
                </div>
              );
            } else {
              return (
                <FormInput
                  key={el.key + "_form_input"}
                  name={el.name}
                  placeholder={el.label}
                  type={el.type}
                  value={el.value}
                  keyValue={el.key}
                  rules={el.rules}
                  disabled={!disabled[3] ? undefined : !disabled[3]}
                />
              );
            }
          })}
          <div className="btn-container" key="signal_configuration_btn">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submitJob1}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default SignalConfiguration;
