import { LastWiresElement } from "../../../../api/requests/installationsService";
import {
  dateType,
  linkType,
  stringType,
  tagType,
} from "../../../../utilities/utilities";
import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../../responsiveTable";
import React from "react";
import { Tag } from "antd";
import { roles } from "../../costants";

export const toHHMMSS = (secs: string) => {
  let sec_num = parseInt(secs, 10); // don't forget the second param
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);

  let h, m;
  if (hours < 10) {
    h = "0" + hours;
  } else {
    h = hours;
  }
  if (minutes < 10) {
    m = "0" + minutes;
  } else {
    m = minutes;
  }
  return h + ":" + m;
};

export const calculatePercentage = (value: string, tot: number) => {
  const v = Number(value);
  return (v / tot) * 100;
};

export const wiresListColumns: (
  role: string,
  handleOpen: () => void,
  handleOpenRfid: () => void,
  setSelectedWire: any,
  wires: LastWiresElement[],
  last: boolean,
  rfid: boolean
) => ResponsiveTableColumnType[] = (
  role,
  handleOpen,
  handleOpenRfid,
  setSelectedWire,
  wires,
  last,
  rfid
) => {
  return [
    {
      label: "Nome",
      name: "name",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, data) => {
          return (
            <div
              className="cell"
              onClick={() => {
                let tmp_wires = wires.filter((w) => w.name === String(value));
                setSelectedWire(tmp_wires[0]);
                handleOpen();
              }}
            >
              <a href="#"> {value} </a>
              {data.tableData[data.rowIndex].in_use ? (
                <Tag color={"blue"}> In uso </Tag>
              ) : null}
            </div>
          );
        },
      },
    },
    {
      label: "RFID",
      name: "rfid",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, data) => {
          if (role === roles.customer || role === "viewer") {
            return <ResponsiveTableCell type={stringType} value={value} />;
          } else {
            return (
              <ResponsiveTableCell
                type={linkType}
                value={value}
                link={() => {
                  let tmp_res: LastWiresElement[] = wires.filter(
                    (w) => w.rfid === value
                  );
                  if (tmp_res.length > 0) {
                    setSelectedWire(tmp_res[0]);
                    handleOpenRfid();
                  } else {
                    setSelectedWire("");
                  }
                }}
              />
            );
          }
        },
      },
    },
    {
      label: "Utilizzo (hh:mm)",
      name: "usage_time",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => {
          const percent = calculatePercentage(
            value,
            data.tableData[data.rowIndex].wire_info.max_usage
          );
          return (
            <ResponsiveTableCell
              type={tagType}
              value={toHHMMSS(value)}
              color={percent >= 90 ? "red" : percent >= 75 ? "orange" : "green"}
            />
          );
        },
      },
    },
    {
      label: "Utilizzo Massimo (h)",
      name: "info",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <ResponsiveTableCell
            type={stringType}
            value={(
              Number(data.tableData[data.rowIndex].wire_info.max_usage) / 3600
            ).toString()}
          />
        ),
      },
    },
    ...dynamicLastUsage(last),
    {
      label: "Modello",
      name: "model",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string) => (
          <ResponsiveTableCell type={stringType} value={value} />
        ),
      },
    },
    {
      label: "Lunghezza (m)",
      name: "info",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <ResponsiveTableCell
            type={stringType}
            value={data.tableData[data.rowIndex].wire_info.length}
          />
        ),
      },
    },
    {
      label: "Data Produzione",
      name: "info",
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: string, data: any) => (
          <ResponsiveTableCell
            type={dateType}
            value={data.tableData[data.rowIndex].wire_info.production_date}
          />
        ),
      },
    },
  ];
};

const dynamicLastUsage: (l: boolean) => ResponsiveTableColumnType[] = (l) => {
  if (l) {
    return [
      {
        label: "Ultimo Utilizzo",
        name: "updated_at",
        options: {
          sort: true,
          filter: false,
          customBodyRender: (value: string) => (
            <ResponsiveTableCell type={dateType} value={value} />
          ),
        },
      },
    ];
  }
  return [];
};
