import "./DeviceConnection.css";
import ButtonItem from "../../ButtonItem";
import { Col, Form, Input, message, Radio, Row, Select, Switch } from "antd";
import React, { useState } from "react";
import TitleWithBack from "../../../containers/TitleWithBack";
import { connectionTitle } from "../title";
import { fw, gsm, installationFields, select, wifi } from "./costant";
import { useHistory } from "react-router";
import { isValidIPv4, isValidSSID, isValidWPA2 } from "../../../api/constants";
import FormInput from "../../input/FormInput";

const { Option } = Select;

type DeviceConnectionConfigurationProps = {
  installation_id: string;
};

const DeviceConnectionConfiguration: React.FC<
  DeviceConnectionConfigurationProps
> = ({ installation_id }) => {
  const history = useHistory();

  const [form] = Form.useForm();
  const [dhcp, setDhcp] = useState<"manuale" | "automatico">("automatico");
  const [formItem, setFormItem] = useState(select.options[1].value);

  const submit = () => {
    form.validateFields().then(async (values) => {
      console.log(values);
      const existingConfig = localStorage.getItem(
        "zerynth_megadiamant_configuration"
      );
      const parsedConfig = existingConfig ? JSON.parse(existingConfig) : {};

      if (values.connection_type === "wifi") {
        if (!values.sid || !isValidSSID(values.sid)) {
          message.error(
            "SSID non valido. Controllare che il nome della rete Wi-Fi sia corretto."
          );
        } else if (!values.password || !isValidWPA2(values.password)) {
          message.error(
            "Password non valida. Assicurarsi che la password rispetti i requisiti WPA2."
          );
        } else if (
          dhcp === "manuale" &&
          (!values.ip || !isValidIPv4(values.ip))
        ) {
          message.error("IP non valido. Inserire un indirizzo IPv4 valido.");
        } else if (
          dhcp === "manuale" &&
          (!values.subnet || !isValidIPv4(values.subnet))
        ) {
          message.error(
            "Maschera di sottorete non valida. Inserire una subnet mask IPv4 valida."
          );
        } else if (
          dhcp === "manuale" &&
          (!values.gateway || !isValidIPv4(values.gateway))
        ) {
          message.error("Gateway non valido. Inserire un gateway IPv4 valido.");
        } else if (
          dhcp === "manuale" &&
          (!values.dns || !isValidIPv4(values.dns))
        ) {
          message.error(
            "DNS non valido. Inserire un indirizzo IPv4 valido per il DNS."
          );
        } else {
          history.push(
            `/installations/${installation_id}/configuration/upload-fw`
          );
          localStorage.setItem(
            "zerynth_megadiamant_configuration",
            JSON.stringify({
              ...parsedConfig,
              ...values,
              dhcp: dhcp,
            })
          );
        }
      }
      if (values.connection_type === "gsm") {
        localStorage.setItem(
          "zerynth_megadiamant_configuration",
          JSON.stringify({
            ...parsedConfig,
            apn: values.operator,
          })
        );
        history.push(
          `/installations/${installation_id}/configuration/upload-fw`
        );
      }
    });
  };

  return (
    <>
      <TitleWithBack title={connectionTitle} key={"add_connection"} />
      <div className="my-connection-container">
        <Form layout="vertical" key={1} name="connection_panel" form={form}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label={select.label}
                name={select.name}
                initialValue={select.options[1].value}
              >
                <Select
                  placeholder={select.placeholder}
                  onChange={(value: string) => {
                    setFormItem(value);
                  }}
                >
                  {select.options.map((opt, i) => {
                    return (
                      <Option value={opt.value} key={i} disabled={opt.disabled}>
                        {opt.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {formItem === select.options[0].value ? (
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={wifi[0].label}
                  name={wifi[0].name}
                  rules={[{ required: true, message: wifi[0].requiredLabel }]}
                >
                  <Input
                    placeholder={wifi[0].placeholder}
                    type={wifi[0].type}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={wifi[1].label}
                  name={wifi[1].name}
                  rules={[{ required: true, message: wifi[1].requiredLabel }]}
                >
                  <Input
                    placeholder={wifi[1].placeholder}
                    type={wifi[1].type}
                  />
                </Form.Item>
              </Col>
              {/*
              <Col span={24}>
                <Switch
                  style={{ marginBottom: "20px" }}
                  checked={dhcp === "manuale"}
                  onChange={(value) =>
                    setDhcp(value ? "manuale" : "automatico")
                  }
                  checkedChildren="Ipv4 manuale"
                  unCheckedChildren="Ipv4 automatico"
                />
              </Col>
              {dhcp === "manuale" && (
                <>
                  <Col span={12}>
                    <Form.Item label="Indirizzo IP" name="ip">
                      <Input placeholder="Indirizzo IP" type="string" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Maschera di sottorete" name="subnet">
                      <Input
                        placeholder="Maschera di sottorete"
                        type="string"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Gateway" name="gateway">
                      <Input placeholder="Gateway" type="string" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="DNS" name="dns">
                      <Input placeholder="DNS" type="string" />
                    </Form.Item>
                  </Col>
                </>
              )}*/}
            </Row>
          ) : (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label={gsm.label}
                  rules={[{ required: true, message: gsm.requiredLabel }]}
                  name={gsm.name}
                >
                  <Select
                    placeholder={gsm.placeholder}
                    onChange={(value: string) => {
                      setFormItem(value);
                    }}
                    defaultValue="iot.secure"
                  >
                    {gsm.options.map((opt, i) => {
                      return (
                        <Option value={opt.value} key={i}>
                          {opt.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            {installationFields.map((el) => {
              if (el.type === "radio") {
                return (
                  <Col span={12} key={el.key + "col"}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {el.label}
                      <Form.Item name={el.name} key={el.key + "formitem"}>
                        <Radio.Group
                          key={el.key}
                          name={el.name}
                          defaultValue={
                            el.options && el.options.length > 0
                              ? el.options[0].value
                              : undefined
                          }
                        >
                          {(el?.options || []).map((opt, i) => {
                            return (
                              <Radio value={opt.value} key={i}>
                                {opt.label}
                              </Radio>
                            );
                          })}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </Col>
                );
              } else {
                return (
                  <Col span={12} key={el.key + "col"}>
                    <FormInput
                      key={el.key + "forminput"}
                      name={el.name}
                      keyValue={el.key}
                      placeholder={el.label}
                      type={el.type ?? "text"}
                      rules={el.rules}
                    />
                  </Col>
                );
              }
            })}
          </Row>
          <div className="btn-container">
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={submit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default DeviceConnectionConfiguration;
