import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import "../../../App.css";
import { dateType, linkType, stringType } from "../../../utilities/utilities";

export const customersListColumns: (
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (linkFunction) => [
  {
    label: "Nome",
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => {
        return (
          <ResponsiveTableCell
            type={linkType}
            value={value}
            link={() => {
              linkFunction(data.tableData[data.rowIndex].uid);
            }}
          />
        );
      },
    },
  },
  {
    label: "Email",
    name: "email",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: "Telefono",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.phone}
        </div>
      ),
    },
  },
  {
    label: "Indirizzo",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.address}
        </div>
      ),
    },
  },
  {
    label: "Città",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.city}
        </div>
      ),
    },
  },
  {
    label: "Provincia",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.province}
        </div>
      ),
    },
  },
  {
    label: "Paese",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.country}
        </div>
      ),
    },
  },
  {
    label: "CAP",
    name: "user_info",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <div className="cell">
          {data.tableData[data.rowIndex].user_info?.zip_code}
        </div>
      ),
    },
  },
  {
    label: "Data Creazione",
    name: "created_at",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
];
