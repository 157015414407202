import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Col, Form, Row } from "antd";
import {
  GetUser,
  UpdateUser as ApiUpdateUser,
} from "../../../api/services/userService";
import React, { useEffect, useState } from "react";
import "../../navbar/Navbar.css";
import TitleWithBack from "../../../containers/TitleWithBack";
import "../../../App.css";
import { User } from "../../../api/requests/userService";
import { userFields } from "./inputs";
import "./UpdateUser.css";
import { useHistory } from "react-router-dom";
import FormInput from "../../input/FormInput";
import { countries } from "../../countries";
import FormSelect from "../../select/FormSelect";
import { updateUserTitle } from "../title";
import OperationResult from "../../OperationResult";

type UpdateUserProps = {
  user_id: string;
};

const UpdateUser: React.FC<UpdateUserProps> = ({ user_id }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const history = useHistory();
  const [form] = Form.useForm();

  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  useEffect(() => {
    GetUser({ user_id: user_id }).then((res) => {
      if (res && !res.err) {
        setSelectedUser(res.user);
      }
    });
  }, []);

  const handleSubmit = () => {
    if (selectedUser) {
      form.validateFields().then((values) => {
        ApiUpdateUser({
          user_id: selectedUser?.uid || "",
          name: values.name,
          email: values.email,
          user_info: {
            phone: values.phone,
            address: values.address,
            city: values.city,
            province: values.province,
            country: values.country,
            zip_code: values.zip_code,
          },
        }).then((res) => {
          if (res && res.result) {
            history.goBack();
            setStatus("success");
          } else {
            setStatus("error");
          }
        });
      });
    }
  };

  if (!selectedUser) {
    return null;
  }

  const formItemLayout = {
    labelCol: {
      sm: {
        span: 8,
      },
    },
    wrapperCol: {
      sm: {
        span: 20,
      },
    },
  };

  if (status) {
    return <OperationResult status={status} operation="update" entity="user" />;
  }

  return (
    <>
      <TitleWithBack title={updateUserTitle} key="update_user_title" />

      <div
        className="my-updateuser-container my-updateuser-container-responsive"
        key="update_user_div"
      >
        <Form
          {...formItemLayout}
          layout="vertical"
          key={1}
          form={form}
          name="user_panel"
        >
          <Row gutter={24} key="companyInfo">
            {userFields.map((el, index) => {
              return (
                <Col span={width <= 575 || height <= 815 ? 12 : 8} key={index}>
                  {el.type !== "select" ? (
                    <FormInput
                      key={el.key + "forminput"}
                      keyValue={el.key}
                      name={el.name}
                      placeholder={el.label}
                      type={el.type ?? "text"}
                      value={
                        (selectedUser as any)[el.key] ||
                        (selectedUser as any)["user_info"][el.key]
                      }
                    />
                  ) : (
                    <FormSelect
                      key={el.key + "forminput"}
                      placeholder={el.label}
                      keyValue={el.key}
                      name={el.name}
                      options={countries}
                      value={
                        (selectedUser as any)[el.key] ||
                        (selectedUser as any)["user_info"][el.key]
                      }
                      rules={el.rules}
                    />
                  )}
                </Col>
              );
            })}
          </Row>
          <div className="btn-container" key="btn_div">
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={handleSubmit}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default UpdateUser;
