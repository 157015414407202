import "./AddWire.css";
import { wireFields } from "./inputs";
import ButtonItem from "../../ButtonItem";
import ButtonConfItem from "../../ButtonConfItem";
import { Col, DatePicker, Modal, Form, Row, Select, Alert, Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import CustomTitle from "../../CustomTitle";
import FormInput from "../../input/FormInput";
import { addWireTitle } from "../title";
import FormSelect from "../../select/FormSelect";
import { GetCustomers } from "../../../api/services/userService";
import { User } from "../../../api/requests/userService";
import "../../select/FormSelect.css";
import { CreateWire } from "../../../api/services/wiresService";
import { CreateWireRequest } from "../../../api/requests/wiresService";
import { dateType, selectInput } from "../../../utilities/utilities";
import OperationResult from "../../OperationResult";

const { Option } = Select;

const AddWire: React.FC = () => {
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState<User[]>([]);
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  useEffect(() => {
    GetCustomers("-1", "", "").then((res) => {
      if (res && res.customers) {
        setCustomers(res.customers);
      }
    });
  }, []);

  const forceCreateWire = useCallback((request: CreateWireRequest) => {
    CreateWire(request).then((res) => {
      if (res && !res.err) {
        setStatus("success");
        form.resetFields();
      } else {
        setStatus("error");
      }
    });
  }, []);

  const createWire = useCallback((request: CreateWireRequest) => {
    CreateWire(request).then((res) => {
      if (res && !res.err) {
        setStatus("success");
        form.resetFields();
      } else {
        if (res?.err?.code === 13) {
          Modal.confirm({
            title:
              "Il cartellino è già associato ad un altro cavo. Vuoi associare questo cartellino a un nuovo cavo?",
            onOk() {
              request.force = true;
              forceCreateWire(request);
            },
            okText: "OK",
            cancelText: "Annulla",
          });
        } else {
          setStatus("error");
        }
      }
    });
  }, []);

  const openPort = async (port: SerialPort, baudRate: number, retries = 3) => {
    if (retries > 0) {
      await port
        .open({ baudRate: baudRate })
        .then(() => {
          console.log("serial port opened successfully");
          navigator.serial.onconnect = () => {
            console.log("connected");
          };

          navigator.serial.ondisconnect = () => {
            console.log("disconnected");
          };
          return true;
        })
        .catch(async (e: any) => {
          console.log("error during port opening:", e);
          console.log("trying to close the port and reopen it again");
          await port.close();
          await openPort(port, baudRate, retries - 1);
          return false;
        });
    } else {
      console.log("retries finished. failed to open the port.");
    }
  };

  const [request, setRequest] = useState<any>({});
  const [reading, setReading] = useState<boolean>(false);
  const [startReading, setStartReading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleReading = async () => {
    let globalPort: any = null;
    setStartReading(true);
    try {
      setError("");
      console.log("values: ", request);
      const req: CreateWireRequest = {
        customer_id: request.customer,
        name: request.name,
        model: request.model,
        description: request.description,
        wire_info: {
          production_date: new Date(request.production_date).toISOString(),
          description: request.description,
          diameter: request.diameter,
          wire_diameter: request.wire_diameter,
          mount_type: request.mount_type,
          pearl_meter: request.pearl_meter,
          length: request.length,
          wire_type: request.wire_type,
          max_usage: String(Number(request.max_usage) * 60 * 60),
          material: request.material,
        },
      };
      navigator.serial
        .requestPort({
          filters: [{ usbVendorId: 0x0403, usbProductId: 0x6001 }],
        })
        .then(async (port) => {
          globalPort = port;
          console.log("port selected:", port);
          await openPort(port, 38400);
          console.log("port opened");
          // Read answer
          const textDecoder = new TextDecoderStream();
          const readableStreamClosed = port.readable.pipeTo(
            textDecoder.writable
          );
          const reader = textDecoder.readable.getReader();
          console.log("reader created");
          let line = "";
          // Ask bundle to device
          const enc = new TextEncoder();
          //write first command
          const writer1 = port.writable.getWriter();
          const data1 = enc.encode(`$:12010E000100000505\r\n`);
          await writer1.write(data1);
          console.log("first command sent");
          writer1.releaseLock();
          //write second command
          const writer2 = port.writable.getWriter();
          const data2 = enc.encode(`$:08012105\r\n`);
          await writer2.write(data2);
          console.log("second command sent");
          writer2.releaseLock();
          console.log("reading");
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              reader.releaseLock();
              break;
            }
            line += value;
            if (line.includes("\n")) {
              reader.releaseLock();
              break;
            }
          }
          console.log("LINE: ", line);
          // Divide 'data' in segmenti separati da '$:'
          const dd = line.split("$:");
          // Estrae il 'tag' dall'ultima parte dei dati, e rimuove la sequenza di escape finale '\r\n'
          const lastSegment = dd[dd.length - 1];
          const tag = lastSegment.split("\\r\\n")[0].slice(-1);
          // Verifica se il tag è '1'
          if (tag === "1") {
            // Prende il penultimo elemento di 'dd', rimuove '\r\n' e preleva una parte della stringa
            const rfid = dd[dd.length - 2].split("\\r\\n")[0];
            console.log("RFID:", rfid.slice(8));
            createWire({ ...req, rfid: rfid.slice(8) });
          } else {
            setError("Tag diverso da '1'. Nessun RFID valido trovato.");
            console.log("Tag diverso da '1'. Nessun RFID valido trovato.");
            setStartReading(false);
          }
          await reader.cancel();
          await readableStreamClosed.catch(() => {});
          if (port) {
            port?.close();
          }
        })
        .catch(async (e) => {
          console.log("no serial port selected:", e);
          setError(
            "Nessuno scanner trovato. Assicurarsi che sia ben collegato."
          );
          setStartReading(false);
          if (globalPort) {
            globalPort?.close();
          }
        });
    } catch (e) {
      console.log("ERROR: ", e);
      setError("Errore durante la lettura del cavo " + String(e));
      setStartReading(false);
    }
  };

  if (status) {
    return <OperationResult status={status} operation="create" entity="wire" />;
  }

  if (reading) {
    return (
      <>
        <CustomTitle title={addWireTitle} key="add_wire_title" />
        <Alert
          message={error ? "Errore" : "Attenzione"}
          description={
            error
              ? error
              : startReading
              ? "Lettura del tag RFID in corso..."
              : "Posizionare la card sullo scanner e premere OK per ottenere il tag RFID del cavo."
          }
          type={error ? "error" : "warning"}
          showIcon
        />
        <Row gutter={24} style={{ paddingTop: "24px" }}>
          <Col span={12}>
            <Button
              size="large"
              type="default"
              style={{ width: "100%" }}
              onClick={() => {
                setError("");
                setReading(false);
                setStartReading(false);
              }}
            >
              Annulla
            </Button>
          </Col>
          <Col span={12}>
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              onClick={handleReading}
              disabled={startReading}
            >
              {error ? "Riprova" : "OK"}
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <CustomTitle title={addWireTitle} key="add_wire_title" />
      <div
        className="my-AddWire-container my-AddWire-container-responsive"
        key={"add_installation_div"}
      >
        <Form layout="vertical" key={1} form={form} name="wire_panel">
          <Row gutter={24} key={"addWire"}>
            <Col xs={24} xl={12}>
              <div className="form__group field" key={"clientidiv"}>
                <label
                  key="customer_label"
                  htmlFor="customer"
                  className="form__label"
                >
                  Cliente
                </label>
                <Form.Item key="clientiform" name="customer">
                  <Select
                    showSearch
                    key="customer"
                    placeholder="Cliente"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      String(option?.children)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      String(optionA.children)
                        .toLowerCase()
                        .localeCompare(String(optionB.children).toLowerCase())
                    }
                  >
                    {customers.map((el: User, index: number) => {
                      return (
                        <Option value={el.uid} key={index}>
                          {el.email}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            {wireFields.map((el) => {
              switch (el.type) {
                case selectInput:
                  return (
                    <Col xs={24} xl={12} key={`${el.key}_col`}>
                      <FormSelect
                        key={`${el.key}_select`}
                        name={el.name}
                        keyValue={el.key}
                        placeholder={el.label}
                        options={el?.options || []}
                        rules={el.rules}
                      />
                    </Col>
                  );
                case dateType:
                  return (
                    <Col xs={24} xl={12} key={`${el.key}_col`}>
                      <div
                        className="form__group field"
                        key={`${el.key}_datadiv`}
                      >
                        <label
                          key={`${el.key}_label`}
                          htmlFor={el.name}
                          className="form__label"
                        >
                          Data Produzione
                        </label>
                        <Form.Item key={el.key} name={el.name} rules={el.rules}>
                          <DatePicker
                            name={el.name}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  );
                default:
                  return (
                    <Col xs={24} xl={12} key={`${el.key}_col`}>
                      <FormInput
                        key={`${el.key}_input`}
                        name={el.name}
                        keyValue={el.key}
                        placeholder={el.label}
                        type={el.type ?? "text"}
                        rules={el.rules}
                      />
                    </Col>
                  );
              }
            })}
          </Row>
          <div className="btn-container" key={"add_installation_btn"}>
            <ButtonConfItem
              buttonLabel="Reset"
              buttonOnCancel={() => {}}
              questionLabel="Il contenuto di tutti i campi sarà cancellato, sei sicuro?"
              buttonOnConfirm={() => {
                form.resetFields();
              }}
            />
            <ButtonItem
              buttonType="primary"
              label="Invio"
              buttonOnClick={() => {
                form.validateFields().then((values) => {
                  setRequest({ ...values });
                  setReading(true);
                });
              }}
            />
          </div>
        </Form>
      </div>
    </>
  );
};

export default AddWire;
