import "./summary.css";
import React, { useCallback, useEffect, useState } from "react";
import { Badge, Card, Divider } from "antd";
import { Installation } from "../../../../api/requests/installationsService";
import Title from "antd/es/typography/Title";
import CustomTag from "./customTag";
import {
  getInstallation,
  GetInstallationUsage,
} from "../../../../api/services/installationsService";
import { Typography } from "@material-ui/core";

type RealTimeSummaryProps = {
  selected_installation: Installation | null;
  startHour: number;
};

const SecondsTohhmm = function (totalSeconds: number) {
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);

  if (isNaN(hours)) {
    hours = 0;
  }
  if (isNaN(minutes)) {
    minutes = 0;
  }
  let result = hours < 10 ? "0" + hours : hours;
  result += ":" + (minutes < 10 ? "0" + minutes : minutes);
  return result;
};

const RealTimeSummary: React.FC<RealTimeSummaryProps> = ({
  selected_installation,
  startHour,
}) => {
  const [installation, setInstallation] = useState<Installation | null>(
    selected_installation
  );
  const [processingTime, setProcessingTime] = useState<number>(0);
  const [alarmTime, setAlarmTime] = useState<number>(0);

  const updateRtInfo = useCallback(() => {
    if (selected_installation) {
      getInstallation({ id: selected_installation.id }).then((res) => {
        if (res && res.installation) {
          setInstallation(res.installation);
        }
      });
    }
  }, [selected_installation]);

  const updateUsageDetails = useCallback(() => {
    const d = new Date();
    const start = new Date(
      new Date().setHours(d.getHours() - startHour)
    ).toISOString();
    const end = d.toISOString();
    if (selected_installation) {
      GetInstallationUsage({
        installation_id: selected_installation.id,
        start: start,
        end: end,
      }).then((res) => {
        if (res && res.usage) {
          if (res.usage.working_time) {
            setProcessingTime(res.usage.working_time);
          } else {
            setProcessingTime(0);
          }
          if (res.usage.alarm_time) {
            setAlarmTime(res.usage.alarm_time);
          } else {
            setAlarmTime(0);
          }
        } else {
          setProcessingTime(0);
          setAlarmTime(0);
        }
      });
    }
  }, [startHour, selected_installation]);

  useEffect(() => {
    updateUsageDetails();
    const intervalId = setInterval(updateUsageDetails, 60000);
    return () => clearInterval(intervalId);
  }, [startHour, updateUsageDetails]);

  useEffect(() => {
    updateRtInfo();
    const intervalId = setInterval(() => {
      updateRtInfo();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
  }, [selected_installation]);

  const mapLedToColor = (v: number | undefined) => {
    switch (v) {
      case 0:
        // RED - Alarm
        return <Badge status="error" />;
      case 1:
        // GREEN
        return <Badge status="success" />;
      case 2:
        // GREEN - Blinking
        return <Badge status="success" className="blinking" />;
      case 3:
        // LED OFF
        return <Badge status="default" />;
      case 4:
        // RED - Blinking
        return <Badge status="error" className="blinking" />;
      case 5:
        // Both blinking
        return (
          <div>
            <Badge status="success" className="blinking" />
            <Badge status="error" className="blinking" />
          </div>
        );

      default:
        return <Badge status="default" />;
    }
  };

  const mapStatusStats = () => {
    if (installation) {
      switch (installation.installation_info.status) {
        case 0:
          // No cards
          return <CustomTag color="red" text="0 cartellini" />;
        case 1:
          // One card
          return <CustomTag color="green" text="1 cartellino" />;
        case 2:
          // Two card
          return <CustomTag color="green" text="2 cartellini" />;
        case 3:
          // More than two card
          return <CustomTag color="red" text="Più di 2 cartellini" />;
        case 4:
          return <CustomTag color="default" text="Standby" />;
        case 5:
          return <CustomTag color="red" text="Alarm" />;
        default:
          // Standby
          return <CustomTag color="default" text="Standby" />;
      }
    }
  };

  return (
    <Card
      style={{ minHeight: "290px", maxHeight: "290px", overflow: "scroll" }}
      hoverable
      title={
        <Title level={4} className="tableTitle">
          Info Real Time
        </Title>
      }
      headStyle={{ maxHeight: "4vh" }}
    >
      <div key="container_div_2">
        <div className="tag-container">
          <Typography>Funzionamento:</Typography>
          {installation && installation.status === "online" ? (
            mapLedToColor(installation.installation_info?.actual_lamp ?? -1)
          ) : (
            <div> --- </div>
          )}
          {installation && installation.status === "online"
            ? mapStatusStats()
            : null}
        </div>
        <Divider />
      </div>

      <div key="container_div_3">
        <div className="tag-container">
          <Typography style={{ textTransform: "none" }}>
            Tempo in Lavorazione (hh:mm):
          </Typography>
          <div>{SecondsTohhmm(processingTime)}</div>
        </div>
        <Divider />
      </div>

      <div key="container_div_4">
        <div className="tag-container">
          <Typography style={{ textTransform: "none" }}>
            Tempo in Allarme (hh:mm):
          </Typography>
          <div>{SecondsTohhmm(alarmTime)}</div>
        </div>
      </div>
    </Card>
  );
};

export default RealTimeSummary;
