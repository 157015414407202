import "./UpdateSubscription.css";
import "../../../App.css";
import React, { useEffect, useState } from "react";
import { GetUser } from "../../../api/services/userService";
import { GetUserRequest, User } from "../../../api/requests/userService";
import { Descriptions } from "antd";
import moment from "moment";

type CustomerSummaryProps = {
  customer_id: string;
};

const CustomerSummary: React.FC<CustomerSummaryProps> = ({ customer_id }) => {
  const [customer, setCustomer] = useState({});
  useEffect(() => {
    GetUser({ user_id: customer_id } as GetUserRequest).then((res) => {
      if (res && res.user) {
        setCustomer(res.user);
      }
    });
  }, []);

  const dateFormatter = (date: string) => {
    return moment(date).format("DD/MM/YY HH:mm");
  };

  return (
    <>
      <Descriptions
        labelStyle={{ fontWeight: "bold" }}
        style={{ marginTop: "2%", marginBottom: "2%" }}
        title="Utente"
        bordered
      >
        <Descriptions.Item label="Nome" span={1.5}>
          {(customer as User).name}
        </Descriptions.Item>
        <Descriptions.Item label="Email" span={1.5}>
          {(customer as User).email}
        </Descriptions.Item>
        <Descriptions.Item label="Data Creazione" span={1.5}>
          {dateFormatter((customer as User).created_at)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default CustomerSummary;
