import { InputType } from "../../types/form/types";
import { UserOutlined } from "@ant-design/icons";
import { emailRules } from "../../types/form/rules";

export const inputs: InputType[] = [
  {
    key: "email",
    name: "email",
    label: "Email",
    type: "email",
    rules: emailRules,
    icon: <UserOutlined className="site-form-item-icon" />,
  },
];
