import { installersListColumns } from "./inputs";
import React from "react";
import { GetInstallers } from "../../../api/services/userService";
import "../../../App.css";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router";
import { updateUserPageUrl } from "../costants";
import ResponsiveTable from "../../responsiveTable";
import { installerListTitle } from "../title";

type InstallerTableProps = {
  logged: boolean;
};

const InstallersListTable: React.FC<InstallerTableProps> = ({ logged }) => {
  document.body.style.background = "#f5f5f5c7";

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(updateUserPageUrl(value));

  return (
    <>
      <CustomTitle title={installerListTitle} />
      <ResponsiveTable
        columns={installersListColumns(linkFunction)}
        getPageAndSortedData={GetInstallers}
        defaultSort="name"
        responseDataName="installers"
      />
    </>
  );
};

export default InstallersListTable;
