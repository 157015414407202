import {
  ResponsiveTableCell,
  ResponsiveTableColumnType,
} from "../../responsiveTable";
import "../../../App.css";
import {
  dateType,
  linkType,
  stringType,
  tagType,
} from "../../../utilities/utilities";

export const installationsListColumns: (
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (linkFunction) => [
  {
    label: "Nome",
    name: "name",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string, data: any) => (
        <ResponsiveTableCell
          type={linkType}
          value={value}
          link={() => linkFunction(data.tableData[data.rowIndex].id)}
        />
      ),
    },
  },
  {
    label: "Descrizione",
    name: "description",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: "Data Creazione",
    name: "created_at",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={dateType} value={value} />
      ),
    },
  },
  {
    label: "Stato Dispositivo",
    name: "status",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell
          type={tagType}
          value={value}
          color={value === "online" ? "green" : "red"}
        />
      ),
    },
  },
];

export const updateInstallationTableColumns: (
  linkFunction: (value: string) => void
) => ResponsiveTableColumnType[] = (linkFunction) => [
  ...installationsListColumns(linkFunction),
  {
    label: "Azienda",
    name: "company",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
  {
    label: "Cliente",
    name: "customer",
    options: {
      sort: true,
      filter: false,
      customBodyRender: (value: string) => (
        <ResponsiveTableCell type={stringType} value={value} />
      ),
    },
  },
];
