import { tableColumn } from "./tableColumns";
import "./SubscriptionsListTable.css";
import "../../../App.css";
import { getAllSubscriptions } from "../../../api/services/subscriptionService";
import React from "react";
import CustomTitle from "../../CustomTitle";
import { useHistory } from "react-router-dom";
import ResponsiveTable from "../../responsiveTable";
import { subscriptionsListTitle } from "../title";

type SubscriptionTableProps = {
  link: (p: string) => string;
};

const SubscriptionsListTable: React.FC<SubscriptionTableProps> = ({ link }) => {
  document.body.style.background = "#f5f5f5c7";

  const history = useHistory();
  const linkFunction: (value: string) => void = (value: string) =>
    history.push(link(value));

  return (
    <>
      <CustomTitle title={subscriptionsListTitle} />
      <ResponsiveTable
        columns={tableColumn(linkFunction)}
        getPageAndSortedData={getAllSubscriptions}
        defaultSort="expiry_date"
        responseDataName="subscriptions"
      />
    </>
  );
};

export default SubscriptionsListTable;
